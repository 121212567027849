import React from 'react';
import IconProps from './types';
import { useTheme } from '@emotion/react';

const Orcid = ({ width, height }: IconProps) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g fillRule="nonzero">
                          <path
                            fill="#FFF"
                            d="M22 11c0 6.076-4.924 11-11 11S0 17.076 0 11 4.924 0 11 0s11 4.924 11 11z"
                            transform="translate(-141 -488) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(0 66) translate(11 10)"
                          />
                          <g fill={theme.colors.secondary_dark}>
                            <path
                              d="M1.573 12.048L.249 12.048.249 2.845 1.573 2.845 1.573 7.004 1.573 12.048zM3.515 2.845H7.09c3.403 0 4.898 2.432 4.898 4.606 0 2.363-1.847 4.606-4.881 4.606H3.515V2.845zm1.323 8.018h2.106c2.999 0 3.686-2.278 3.686-3.412 0-1.848-1.177-3.412-3.755-3.412H4.838v6.824zM1.779.928c0 .473-.387.868-.868.868S.043 1.401.043.928C.043.447.43.06.911.06s.868.395.868.868z"
                              transform="translate(-141 -488) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(0 66) translate(11 10) translate(5.844 3.953)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Orcid;
