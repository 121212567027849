import React from 'react';

import IconProps from './types';

const GoogleLogo = ({ width, height, style }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#FFF"
                            d="M4.424 12.065l-.695 2.594-2.54.054c-1.56-2.905-1.587-6.391-.073-9.32l2.261.415.99 2.247c-.446 1.303-.426 2.72.055 4.01h.002z"
                            transform="translate(-142 -423) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(12 11)"
                          />
                          <path
                            fill="#FFF"
                            d="M19.787 8.119c.69 3.626-.679 7.337-3.558 9.647l-2.846-.145-.403-2.516c1.176-.69 2.08-1.761 2.559-3.037h-5.34v-3.95h9.588z"
                            transform="translate(-142 -423) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(12 11)"
                          />
                          <path
                            fill="#FFF"
                            d="M16.228 17.766c-4.3 3.45-10.584 2.762-14.035-1.538-.38-.474-.716-.98-1.004-1.515l3.236-2.648c1.15 3.07 4.572 4.626 7.642 3.475.315-.118.62-.264.912-.434l3.249 2.66z"
                            transform="translate(-142 -423) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(12 11)"
                          />
                          <path
                            fill="#FFF"
                            d="M16.35 2.3l-3.232 2.648c-2.782-1.734-6.443-.884-8.176 1.898-.237.38-.43.785-.576 1.209L1.115 5.393C3.65.497 9.675-1.415 14.57 1.12c.634.328 1.231.724 1.78 1.18"
                            transform="translate(-142 -423) translate(120 -25) translate(10) translate(0 273) translate(0 164) translate(12 11)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GoogleLogo;
